import { render, staticRenderFns } from "./club-data.vue?vue&type=template&id=7488bea5&scoped=true&"
import script from "./club-data.vue?vue&type=script&lang=js&"
export * from "./club-data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7488bea5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Sunil\\Subsapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7488bea5')) {
      api.createRecord('7488bea5', component.options)
    } else {
      api.reload('7488bea5', component.options)
    }
    module.hot.accept("./club-data.vue?vue&type=template&id=7488bea5&scoped=true&", function () {
      api.rerender('7488bea5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clubs/club-data.vue"
export default component.exports