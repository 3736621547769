var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { lg: 12, md: 24, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                { attrs: { title: "Club Details" } },
                [
                  _c(
                    "a-form",
                    { attrs: { form: _vm.form, layout: "vertical" } },
                    [
                      _c("a-form-item", { attrs: { label: "Club Name" } }, [
                        _c("input", {
                          attrs: { disabled: "" },
                          domProps: { value: _vm.clubData.club_name },
                        }),
                      ]),
                      _c("a-form-item", { attrs: { label: "Club Email" } }, [
                        _c("input", {
                          attrs: { disabled: "" },
                          domProps: { value: _vm.clubData.email },
                        }),
                      ]),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Contact Number" } },
                        [
                          _c("input", {
                            attrs: { disabled: "" },
                            domProps: { value: _vm.clubData.contact_number },
                          }),
                        ]
                      ),
                      _c("a-form-item", { attrs: { label: "Main Address" } }, [
                        _c("input", {
                          attrs: { disabled: "" },
                          domProps: { value: _vm.clubData.address },
                        }),
                      ]),
                      _c("a-form-item", { attrs: { label: "Facebook URL" } }, [
                        _c("input", {
                          attrs: { disabled: "" },
                          domProps: { value: _vm.clubData.facebook },
                        }),
                      ]),
                      _c("a-form-item", { attrs: { label: "Twitter URL" } }, [
                        _c("input", {
                          attrs: { disabled: "" },
                          domProps: { value: _vm.clubData.twitter },
                        }),
                      ]),
                      _c("a-form-item", { attrs: { label: "Website URL" } }, [
                        _c("input", {
                          attrs: { disabled: "" },
                          domProps: { value: _vm.clubData.website },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }